<template>
  <div class="management-info-page">
    <bread-crumb
      v-if="modelInfo"
      :name="modelInfo.name"
    />
    <div class="page-title-row">
      <div class="title">
        {{ $t('sideNav.modelEvaluation') }}
      </div>
    </div>
    <div class="description description-title">
      <i18n
        path="modelPerformance.performanceInfo"
        tag="span"
      >
        <template #testing_data>
          <span class="description__hover tooltip-container">
            {{ $t('modelPerformance.testingData') }}
            <div
              v-if="!isLoading"
              class="tooltip"
            >
              {{ performanceDetailInfo }}
            </div>
          </span>
        </template>
      </i18n>
    </div>
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <template v-else>
      <div
        v-if="testingModelData"
        class="info-block"
      >
        <div class="info-block__group">
          <p class="info-block__title">
            {{ $t('modelPerformance.dataDistribution') }}
          </p>
          <div class="info-block__content model-info">
            <div class="model-info__title">
              <span class="model-info__title-label">{{ modelSource.label }}</span>
              <span>{{ modelSource.value }}</span>
            </div>
            <div class="model-info__content">
              <div
                v-for="info in testingModelData"
                :key="info.label"
                class="model-info__wrapper"
              >
                <span class="model-info__wrapper-label">{{ info.label }}</span>
                <span class="model-info__wrapper-value">{{ info.value }}</span>
                <span
                  v-if="info.unit"
                  model-info__wrapper-value
                >
                  {{ info.unit }}
                </span>
              </div>
            </div>
            <div class="description">
              <i18n
                v-if="ignoreCount"
                path="modelPerformance.ignoreDescription"
                tag="span"
              >
                <template #ignore_count>
                  <span class="description__highlight">{{ ignoreCount }}{{ $t('modelPerformance.record') }}</span>
                </template>
              </i18n>
            </div>
          </div>
        </div>
      </div>
      <div class="info-block">
        <model-performance
          :training-type="modelTrainingType"
          :curve-info="curveInfo"
        >
          <template slot="description">
            <div class="description description-content">
              <i18n
                path="modelPerformance.performanceDescription"
                tag="span"
              >
                <template #testing_data>
                  <span class="description__hover tooltip-container">
                    {{ $t('modelPerformance.testingData') }}
                    <div class="tooltip">{{ performanceDetailInfo }}</div>
                  </span>
                </template>
                <template #testing_data_count>
                  <span class="description__highlight">{{ modelPerformanceData.modelPerformance ? formatComma(modelPerformanceData.modelPerformance.testingCount) : formatComma(modelPerformanceData.modelDataDistribution.testingDataCount) }}{{ $t('modelPerformance.record') }}</span>
                </template>
              </i18n>
              <span
                v-if="modelTrainingType === 'Binary'"
                class="description__highlight"
              >
                {{ $t('modelInfo.threshold') }} = {{ autoRound(modelInfo.threshold) }}
              </span>
            </div>
          </template>
          <template slot="metrics">
            <display-metrics
              :training-type="modelTrainingType"
              :info="modelPerformanceData"
            />
          </template>
          <template
            v-if="modelTrainingType === 'Regression'"
            slot="chart"
          >
            <div class="group-title">
              {{ $t('modelPerformance.modelDeviationDistribution') }}
              <remind-popover
                :content="$t('modelPerformance.reminder.modelDeviationDistributionExplain')"
                icon-name="description"
              />
            </div>
            <model-task
              :component-id="modelPerformanceData.residualDistributionId"
              :is-show-toolbox="false"
              :is-show-warning-enable="false"
              height="275px"
              direction="horizontal"
            />
          </template>
          <template
            v-else-if="modelTrainingType === 'Binary'"
            slot="chart"
          >
            <div class="group-title">
              {{ $t('model.confusionMatrix') }}
              <el-popover
                :offset="-60"
                width="548"
                trigger="hover"
                placement="bottom-start"
                popper-class="el-popover--training-reminder"
              >
                <svg-icon
                  slot="reference"
                  style="color: #A7A7A7"
                  icon-class="description"
                />
                <metric-content
                  :metrics-type="modelTrainingType"
                  display-metric="matrix"
                />
              </el-popover>
            </div>
            <display-confusion-matrix
              :info="modelPerformanceData"
              class="confusion-matrix"
            />
          </template>
        </model-performance>
        <div
          v-if="modelPerformanceData.trainingDataDistributionComparisonId"
          class="info-block__group"
        >
          <p class="info-block__title">
            {{ $t('modelPerformance.compareResultPerformance') }}
            <remind-popover
              :content="$t('modelPerformance.reminder.compareResultPerformance')"
              icon-name="description"
              width="250"
            />
          </p>
          <div class="info-block__content">
            <model-task
              :component-id="modelPerformanceData.trainingDataDistributionComparisonId"
              :is-show-toolbox="false"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getModelInfo, getBinaryModelPerformance, getRegressionModelPerformance } from '@/API/Model'
import BreadCrumb from './components/BreadCrumb'
import ModelPerformance from './components/ModelPerformance'
import MetricContent from '../modelTraining/components/MetricContent'
import RemindPopover from '@/components/popover/RemindPopover.vue'

export default {
  name: 'ModelEvaluation',
  components: {
    BreadCrumb,
    ModelPerformance,
    RemindPopover,
    MetricContent
  },
  data () {
    return {
      isLoading: false,
      modelInfo: null,
      modelPerformanceData: null,
      dataOrigin: {
        source: null,
        frame: null
      },
      curveInfo: {},
      modelTrainingType: null,
      ignoreCount: null
    }
  },
  computed: {
    modelId () {
      return this.$route.params.model_id
    },
    modelSource () {
      const trainingData = this.modelPerformanceData?.modelDataDistribution.trainingDataSrc
      let trainingDataSourceString

      if (!trainingData.dataFramePrimaryAlias) {
        if (!trainingData.dataSourceName) {
          trainingDataSourceString = this.$t('modelPerformance.dataSourceBeenDeleted')
        }
        trainingDataSourceString = this.$t('modelPerformance.dataFrameBeenDeleted')
      } else {
        trainingDataSourceString = `${trainingData.dataSourceName} / ${trainingData.dataFramePrimaryAlias}`
      }

      return {
        label: this.$t('modelPerformance.trainingDataSource'),
        value: trainingDataSourceString
      }
    },
    trainingRatio () {
      let trainingRatio = this.modelPerformanceData?.modelDataDistribution.ratio
      let isFloat = true
      let count = 0
      while (isFloat) {
        trainingRatio = trainingRatio * 10
        count += 1
        if (Number.isInteger(trainingRatio)) {
          isFloat = false
        }
      }
      let testingRatio = 10 ** count - trainingRatio

      return `${trainingRatio} : ${testingRatio}`
    },
    testingModelData () {
      const trainingData = this.modelPerformanceData?.modelDataDistribution
      return [
        {
          label: this.$t('modelPerformance.trainingAndTestingRatio'),
          value: this.trainingRatio
        }, {
          label: this.$t('modelPerformance.validDataRows'),
          value: this.formatComma(trainingData.validDataCount),
          unit: this.$t('modelPerformance.record')
        }, {
          label: this.$t('modelPerformance.trainingDataAmount'),
          value: this.formatComma(this.nullCheck(trainingData.trainingDataCount)),
          unit: this.$t('modelPerformance.record')
        }, {
          label: this.$t('modelPerformance.testingDataAmount'),
          value: this.formatComma(this.nullCheck(trainingData.testingDataCount)),
          unit: this.$t('modelPerformance.record')
        }
      ]
    },
    performanceDetailInfo () {
      return this.$t('modelPerformance.performanceDetailInfo', {
        df_name: this.modelPerformanceData.modelDataDistribution.trainingDataSrc.dataFramePrimaryAlias,
        testing_ratio: this.trainingRatio.split(':')[1] * 10
      })
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      try {
        this.modelInfo = await getModelInfo(this.modelId)
        this.modelTrainingType = this.lowercaseEachLetterExceptFirst(this.modelInfo.modelTrainingType)
        const performanceAPI = {
          Regression: getRegressionModelPerformance,
          Binary: getBinaryModelPerformance
        }
        this.modelPerformanceData = await performanceAPI[this.modelTrainingType](this.modelId)
        if (this.modelTrainingType === 'Binary') {
          this.ignoreCount = this.modelPerformanceData.modelPerformance.ignoreCount
          this.curveInfo.ROC = this.modelPerformanceData.ROC_tc_id
          this.curveInfo.PR = this.modelPerformanceData.PR_tc_id
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 13px;
  font-weight: 400;
  color: #DDDDDD;
  &.description-title {
    margin-bottom: 16px;
  }
  &__hover {
    font-weight: 600;
    &:hover {
      color: $theme-color-primary;
    }
    .tooltip {
      padding: 12px;
      width: 200px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      white-space: normal;
      color: #DDDDDD;
    }
  }
  &__highlight {
    color: #44D2FF;
  }
}

.model-info {
  &__title {
    margin-bottom: 20px;
    &-label {
      font-size: 13px;
      font-weight: 600;
      color: #A7A7A7;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
  &__wrapper {
    &:not(:first-of-type) {
      padding: 0 20px;
      border-left: 0.628136px solid #52696A;
    }
    &-label {
      font-size: 13px;
      font-weight: 600;
      color: #A7A7A7;
    }
  }
}

.info-block__group {
  margin-bottom: 24px;
}

::v-deep .group-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
}
</style>
